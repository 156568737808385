import { ROUTES } from "commons/consts";

const actions = {
  SET_STATE: "menu/SET_STATE",
  SET_BUYER_DATA: "menu/SET_BUYER_DATA",
  SET_SELLER_DATA: "menu/SET_SELLER_DATA",
  SET_ADMIN_DATA: "menu/SET_ADMIN_DATA",
  SET_USER_DATA: "menu/SET_USER_DATA"
};

export default actions;

export async function getUserData() {
  return [
    {
      title: "Service Request",
      key: "ServiceRequest",
      icon: "fe fe-package ",
      url: ROUTES.SERVICE_REQUEST_ROUTE
    },
    {
      title: "Shipment",
      key: "Shipment",
      icon: "fe fe-truck ",
      url: ROUTES.SHIPMENT_ROUTE
    },
    // {
    //   title: "Device",
    //   key: "Device",
    //   icon: "fe fe-monitor ",
    //   url: ""
    // },
    {
      title: "Financials",
      key: "Financials",
      icon: "fe fe-trending-up",
      url: "",
      children: [
        {
          title: "Wallet",
          key: "Wallet",
          url: ROUTES.WALLET_ROUTE
        },
        // {
        //   title: "Account Summary",
        //   key: "accountSummary",
        //   url: ROUTES.ACCOUNT_SUMMARY_ROUTE
        // },
        {
          title: "Withdraw Fund",
          key: "withdrawFund",
          url: ROUTES.WITHDRAW_FUND_ROUTE
        },
        {
          title: "Add Fund",
          key: "Add Fund",
          url: ROUTES.ADD_FUNDS_ROUTE
        }
      ]
    }
  ];
}
